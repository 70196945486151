
.premises_list_view {
    display: flex;
}
.premises_list_view_content {
    flex-direction: column;
    padding: 3px;
}
.cechy_para {
    display: flex;
    justify-content:space-between;
}
.cechy_label {
    width: 50px;
}
.cechy_value {
    font-weight: bold;
    text-align: right;
    white-space: nowrap;
}
.edit_panel {
    display: flex;
    justify-content:center;
}
.edit_panel_right {
    margin-left: 15px;
}
.input_number {
    width: 100px;
}

.list_uwagi {
    font-weight: bold;
}
input.rank_input {
    width: 40px;
}
TextArea {
    width: 100%;
}
.premises_header {
    margin-top: 1.5em;
}
.url_input {
    width: 600px;
}

.rank_update {
    display: flex;
    margin-bottom: 5px;
}
.rank_update_value {
    font-size: larger;
    font-weight: bold;
}
.title_input {
    width: 100%;
}