.map-component {
    margin-top: -1em;
    display: flex;
}

.leaflet-container {
    height: calc(100vh - 184px);
    width: 70%;
    margin: 0 auto;
    font-family: Lato, 'Helvetica Neue', Arial, Helvetica, sans-serif;
}

.leaflet-container .header {
    font-size: 110%;
    margin-bottom: 0.5em;
}

.leaflet-container .buttons {
    text-align: right;
}

.map-controls {
    height: calc(100vh - 184px);
    width: 43%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.map-controls .org-list {
    min-height: 20%;
    margin-top: 0 !important;
    overflow-y: auto;
    padding: 0.2em;
}

.map-controls .ui.message {
    margin-left: 1em;
    margin-right: 1em;
}

.ui.celled.list.org-list>.item:first-child {
    border-top: none;
}

.ui.celled.list.org-list>.item:last-child {
    border-bottom: none;
}

.map-controls .org-filters {
    min-height: 50vh;
    overflow-y: auto;
    padding: 0.6em;
}

.map-controls .org-list .item {
    padding-top: .65em !important;
    padding-bottom: .65em !important;
}

.map-controls .org-list .item .floated.content {
    color: rgba(0, 0, 0, 0.55);
}

.map-controls .org-list .item .cityAndCountry {
    margin-right: .8em;
}

.map-controls .org-list .item .nameEnglish:not([disabled]) {
    cursor: pointer;
}

.map-controls .org-list .item .nameEnglish {
    vertical-align: middle;
    display: inline-block;
    max-width: 32em;
}

.ui.celled.selection.list.ui.basic.segment.org-list .item:hover, 
.ui.celled.selection.list.ui.basic.segment.org-list .item {
    color: rgba(0, 0, 0, 0.87);
    cursor: default;
}

.defineParametersLabel {
    margin: 0.4em 0 0.8em 0 !important;
}