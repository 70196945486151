.siteParent {
    display: flex;
    min-height: 100vh;
    flex-direction: column;
}

.siteContent {
    flex: 1 0 auto;
}

.searchControls {
    padding: 1.2rem;
    padding-bottom: 0;
}

.ui.menu.topMenu {
    background-color: #fafafb !important;
    margin-top: -30px !important;
}

.loginMenu {
    padding-right: 230 px !important;
}

.loggedUserLabel {
    color: #2185d0;
    font-weight: bold;
}

.inputDate input::placeholder {
    font-size: 85%;
}

.react-datepicker-popper {
    z-index: 3 !important;
}

i.icon.iconTip {
    font-size: 1.2em;
    margin-right: 0;
    margin-left: .15rem;
}

.preLine {
    white-space: pre-line;
}

textarea {
    max-height: 250px;
}

.ui.search>.results {
    overflow-y: auto;
    max-height: 350px;
    width: 25em !important;
}

li.validationErrorClickable a {
    cursor: pointer;
    color: #9f3a38;
}

.error a.dropdown-trigger {
    background-color: #ffffff;
}

.toastError ul {
    padding-left: 2em;
}

.ui.disabled.readOnly.dropdown, .ui.dropdown .menu>.disabled.readOnly.item {
    opacity: 1;
}

.ui.form .disabled.readOnly.field, .ui.form .disabled.readOnly.fields .field, .ui.form .field :disabled, .ui.input.inputDate.disabled.readOnly {
    opacity: 1;
}

.ui.form .field.disabled.readOnly>label, .ui.form .fields.disabled.readOnly>label {
    opacity: 1;
}

input[readonly], textarea[readonly] {
    opacity: 0.7;
}

.ui.disabled.dropdown {
    opacity: 0.7 !important;
}

.react-dropdown-tree-select.ui.dropdown.readOnly {
    opacity: 0.7;
    pointer-events: none;
}

.react-dropdown-tree-select.readOnly .tag-remove {
    display: none;
}

button.link {
    background: none!important;
    border: none;
    padding: 0!important;
    color: #069;
    text-decoration: none;
    cursor: pointer;
    text-align: left;
}

.ant-switch.ant-switch-checked {
    background-color: #2185d0;
}

.field.disabled .ui.label>.delete.icon {
    display: none;
}

.ant-typography {
    color: rgba(0, 0, 0, .87) !important;
}

.forgotPassword {
    margin: auto !important;
    width: 450px;
}


.ui.simple.active.dropdown>.menu.dropdownMenu,
.ui.simple.dropdown:hover>.menu.dropdownMenu {
    top: 80% !important;
}

.topMenu .dropdownItem {
    padding: 0 !important;
}
.scrollX {
    overflow-x: auto;
}

.userManualBtn {
    color: black !important;
}

span.email {
    color:#ff0000;
}