.camt {
    /* background-repeat: no-repeat;
    background-size: cover;
    background-image: url(../assets/camt.jpg); */
    /* padding: 220px 0; */
    height: 80vh;
    display: flex;
    align-items: center;
    margin-top: -20px;
}

.camt .sheader {
    font-family: Montserrat, Lato, 'Helvetica Neue', Arial, Helvetica, sans-serif;
    padding: 40px 20px;
    background: rgba(0, 22, 61, .8);
    width: 70%;
    margin: 0 auto;
    text-align: center;
    color: #fff;
    font-weight: 500;
    text-transform: uppercase;
}

.camt .sbuttons {
    margin: 1.5em;
}

.camt .sbuttons .button {
    font-family: Montserrat, Lato, 'Helvetica Neue', Arial, Helvetica, sans-serif;
    text-transform: uppercase;
    margin: 0 .5em;
    background: #fff;
    color: #000;
    border-radius: 0;
    padding: 10px 50px;
}

.camt .sbuttons .button:hover {
    background: #d6730e;
    color: #fff;
}

p.opis_main_page {
    padding: 50px 0 0 0;
    font-family: Montserrat, Lato, 'Helvetica Neue', Arial, Helvetica, sans-serif;
    font-size: large;
    line-height: 1.6;
}

.logged_container {
    margin: 30px 0;
}