#pwr_login {
    text-align: center;
    background-color: white;
    padding: 2em 0;
}

#wymagane_uwierzytelnienie {
  font-size: 1.8rem;
  font-weight: 600;
}
#logowanie_domenowe {
  margin-top: 20px;;
  font-size: 1.2rem;
}

button.login_button {
  display: inline-block;
  margin: 2em 0;
  padding: 10px 20px;
  color: #fff;
  background-color: #d51e25;
  border: 2px dotted #d51e25;
  border-radius: 6px;
}
a.login_link, a:hover.login_link {
  display: inline-block;
  margin: 2em 0;
  padding: 10px 20px;
  color: #fff;
  background-color: #d51e25;
  border: 2px dotted #d51e25;
  border-radius: 6px;
 }
 a:hover.login_link

fieldset.development {
  background-color: #eeeeee;
  border: 2px groove;
  margin: auto;
  width: 800px;
}