.formScrollingContent {
    padding-right: 1em;
}

.react-dropdown-tree-select .toggle {
    font: normal normal normal 12px/1 FontAwesome;
    vertical-align: middle;
}

.react-dropdown-tree-select .toggle.collapsed::after {
    content: "\f067";
}

.react-dropdown-tree-select .toggle.expanded::after {
    content: "\f068";
}

.react-dropdown-tree-select .dropdown .dropdown-trigger.arrow.top::after, .react-dropdown-tree-select .dropdown .dropdown-trigger.arrow.bottom::after {
    font: normal normal normal 12px/1 FontAwesome;
    content: '\f0d7';
    margin-left: -23px;
}

.react-dropdown-tree-select .dropdown .dropdown-trigger {
    line-height: 35px;
    padding: 0;
    border: none;
    overflow: visible;
}

.react-dropdown-tree-select.readOnly .tag-list::after,
.react-dropdown-tree-select.readOnly .dropdown-trigger::after {
    content: '' !important;
    display: none;
}
.react-dropdown-tree-select.readOnly .tag-item:last-child {
    display: none;
}
.react-dropdown-tree-select.readOnly .tag-item {
    float: left;
}
.react-dropdown-tree-select.ui.dropdown.readOnly {
    margin-top: 0;
    margin-bottom: 2px;
}

.react-dropdown-tree-select .dropdown input.search {
    width: 250px;
}

.stepInfrastructure .react-dropdown-tree-select .dropdown input.search {
    width: 350px;
}

.react-dropdown-tree-select .dropdown .tag-item {
    margin: 2px 4px;
}

.react-dropdown-tree-select .dropdown .tag-item .tag-remove {
    visibility: hidden;
    position: relative;
    color: rgba(0, 0, 0, .6);
    opacity: 0.5;
}

.react-dropdown-tree-select .dropdown .tag-item .tag-remove:hover {
    opacity: 1;
}

.react-dropdown-tree-select .dropdown .tag-item .tag-remove::after {
    font: normal normal normal 16px/1 FontAwesome;
    content: '\f00d';
    visibility: visible;
     position: absolute;
    top: -3px;
    left: 8px;
}

.react-dropdown-tree-select .dropdown .tag-item :not(input):not(button) {
    display: inline-block;
    line-height: 1;
    vertical-align: baseline;
    background-color: rgb(232, 232, 232);
    background-image: none;
    color: rgba(0, 0, 0, 0.6);
    text-transform: none;
    font-weight: 700;
    margin: 0px 0.142857em;
    padding: 0.5833em 0.833em;
    border-width: 0px;
    border-style: solid;
    border-color: transparent;
    border-image: initial;
    border-radius: 0.285714rem;
    transition: background 0.1s ease;
    user-select: none;
    display: inline-block;
    vertical-align: top;
    white-space: normal;
    font-size: 0.9em;
    box-shadow: rgba(34, 36, 38, 0.15) 0px 0px 0px 1px inset;
    margin: 0.142857rem 0.285714rem 0.142857rem 0px;
}

.react-dropdown-tree-select .dropdown .dropdown-content {
    overflow: auto;
    max-height: 200px;
    padding: 7px;
    margin: 0px 10px;
    border-left: 1px solid rgba(0, 0, 0, .05);
}

.react-dropdown-tree-select.dropdown {
    margin-top: -2px;
    margin-left: -4px;
    position: relative;
}

.react-dropdown-tree-select .dropdown label {
   vertical-align: middle;
}

.react-dropdown-tree-select .dropdown label .checkbox-item {
    vertical-align: middle;
}

.message.validationErrors ul {
    columns: 3;
}

.stepContent {
    margin-bottom: .3em;
}

fieldset.segment legend {
    padding: 0 5px;
    font-weight: bold;
    font-size: 110%;
}

.checkbox.authorized {
    margin-top: 0.5em;
    padding-left: 0.2em;
}