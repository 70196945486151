.total_records_div {
    margin-top: 0.6em;
}
.data_request_div {
    margin-top: 1em;
}
div.disable-links {
    pointer-events: none;
}

.columnUpdated {
    width: 11em;
}