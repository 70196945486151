.projectPartnersContainer {
    min-height: 40px;
}

.projectPartnersContainer .searchBar {
    display: flex;
    justify-content: space-between;
}

table.projectPartners {
    line-height: 1.3em;
}

table.projectPartners tbody {
    display: block;
    max-height: 400px;
    overflow-y: auto;
}

table.projectPartners thead, table.projectPartners tbody tr {
    display: table;
    width: 100%;
    table-layout: fixed;
}

table.projectPartners .toggle:after {
    content: "";
}